import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['field', 'button']
  static values = { lang: String }

  connect() {
    this.makeButtonActive()
  }

  showFields(event) {
    event.stopPropagation() & event.preventDefault()
    this.langValue = event.target.dataset.lang
    this.makeButtonActive()
    this.fieldTargets.forEach(element => {
      if (element.dataset.lang == event.target.dataset.lang) {
        element.classList.remove('hidden')
      } else {
        if (!element.classList.contains('hidden')) {
          element.classList.add('hidden')
        }
      }
    });
  }

  makeButtonActive() {
    this.buttonTargets.forEach(buttonTarget => {
      if (buttonTarget.dataset.lang == this.langValue)
        buttonTarget.classList.add('active')
      else
        buttonTarget.classList.remove('active')
    })
  }
}
