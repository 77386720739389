import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["subdomain", "output"]

  connect() {
    this.updateOutput()
  }

  updateOutput() {
    const subdomain = this.subdomainTarget.value
    const displayText = `${subdomain}.yunoo.io`
    this.outputTarget.textContent = displayText
  }
}
