import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  connect(){
    let flash = this.element
    let removeFlash = () => {
      flash.classList.add('hidden');
    }
    if (flash) {
      setTimeout(removeFlash, 5000);
    }
  }
}
