import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'selectLearningItemsStep',
    'confirmExpectedTransactionStep',
    'finalStep',
    'selectLearningItemsForm',
    'content',
    'submitButton',
    'form',
    'collaboratorCheckboxes'
  ]


  validate(event) {
    var formData = Object.fromEntries(new FormData(this.selectLearningItemsFormTarget).entries());
    let learningItemGlobalProductIds = formData['learning_item_global_product_ids[]']
    if (learningItemGlobalProductIds) {
      this.submitButtonTarget.classList.add('btn-active-primary')
      this.submitButtonTarget.classList.remove('btn-disabled')
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.classList.remove('btn-active-primary')
      this.submitButtonTarget.classList.add('btn-disabled')
      this.submitButtonTarget.disabled = true
    }
  }

  resultSelectLearningItems(event){
    // event is ajax success

    const [, , xhr] = event.detail;
    if (xhr.response.length > 0) {
      this.confirmExpectedTransactionStepTarget.classList.remove('border-gray-200')
      this.confirmExpectedTransactionStepTarget.classList.add('border-bg-primary')
      this.confirmExpectedTransactionStepTarget.classList.remove('text-gray-500')
      this.confirmExpectedTransactionStepTarget.classList.add('bg-primary-as-text')
      return this.contentTarget.innerHTML = xhr.response
    }
  }

  errorSelectLearningItems(event) {
    const [, , xhr] = event.detail;
    if (xhr.status == 402){
      // TODO add i18n yarn package https://stackoverflow.com/questions/59181356/how-to-use-i18n-js-with-webpacker-in-rails-6
      return alert("You don't have enough credit to perform that operation")
    }
  }

  resultSubmitRegistrations(event){
    // event is ajax success
    const [, , xhr] = event.detail;
    if (xhr.response.length > 0) {
      this.finalStepTarget.classList.remove('border-gray-200')
      this.finalStepTarget.classList.add('border-bg-primary')
      this.finalStepTarget.classList.remove('text-gray-500')
      this.finalStepTarget.classList.add('bg-primary-as-text')
      return this.contentTarget.innerHTML = xhr.response
    }
  }
}
