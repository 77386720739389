import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown"];

  connect() {
    // Optional: Hide the dropdown initially
    this.hideDropdown();
  }

  search(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.dropdownTarget.querySelectorAll("li").forEach((li) => {
      const text = li.textContent.toLowerCase();
      li.style.display = text.includes(searchTerm) ? "" : "none";
    });
  }

  select(event) {
    // Retrieve the selected value and label from the clicked list item
    const selectedValue = event.currentTarget.dataset.value;
    const selectedLabel = event.currentTarget.querySelector('span').textContent;
  
    // Find the text input field within the same Stimulus controller
    const textInput = this.element.querySelector('input[type="text"]');
  
    // Update the value of the text input field with the selected label
    if (textInput) {
      textInput.value = selectedLabel;
    }
  
    // Optionally, update a hidden field with the selected value
    const hiddenInput = this.element.querySelector('input[type="hidden"]');
    if (hiddenInput) {
      hiddenInput.value = selectedValue;
    }
  
    // Hide the dropdown
    const dropdown = this.element.querySelector('[data-image-select-target="dropdown"]');
    if (dropdown) {
      dropdown.classList.add('hidden');
    }
  }
  
  // Optional: Method to toggle the dropdown visibility
  toggleDropdown() {
    const dropdown = this.element.querySelector('[data-image-select-target="dropdown"]');
    if (dropdown) {
      dropdown.classList.toggle('hidden');
    }
  }
  
  
  
}