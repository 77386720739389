import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["modal", "modalPanel"]
  static values = {
    remote: Boolean
  }


  connect(){
    // if modal is displayed without button, in open state
    if (this.isDisplayed()) {
      this.open()
    }
  }

  open(event) {
    if (event) event.stopPropagation() & event.preventDefault()

    // Lock the scroll and save current scroll position
    this.lockScroll()
    
    this.modalTarget.classList.remove("hidden")
  }
  
  close(){
    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();
    if (this.remoteValue) {
      this.element.classList.add("hidden")
    } else {
      this.modalTarget.classList.add("hidden")
    }
  }
 

  closeOutside(event) {
    if (this.isInsideModalPanel(event.target)) return
    this.close()
  }

  closeWithKeyboard(event) {
    if (this.isDisplayed() && event.keyCode === 27) this.close() 
  }

  isInsideModalPanel(node) {
    while (node) {
      if (node === this.modalPanelTarget) return true
      node = node.parentNode
    }
    return false
  }

  isDisplayed() {
    return !this.modalTarget.classList.contains('hidden')
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();
    
    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return;

    document.documentElement.scrollTop = this.scrollPosition;
  }
}
