import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['star']

  connect() {
    this.fillRating()
  }

  fillRating() {
    const num = Math.floor(this.data.get('rankingPercentage'))
    const starCount = this.starTargets.length
    const starValue = Math.floor(100 / starCount)
    const ratingRounded = (Math.floor(num)/starValue)
    const leftOver = (Math.floor(num) % starValue) * starCount
    let firstEmptyStarIndex = Math.floor(ratingRounded)
    if (leftOver !== 0) {
      const partialStar = [...this.starTargets][Math.floor(ratingRounded)]
      partialStar.style.width = `${leftOver}%`
      firstEmptyStarIndex += 1
    }
    const emptyStars =
      [...this.starTargets].slice(firstEmptyStarIndex, starCount)
    emptyStars.forEach(emptyStar => emptyStar.style.width = '0%' )
  }
}

