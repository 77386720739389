import Reveal from "stimulus-reveal-controller";

export default class extends Reveal {
  static targets = ["burger", "cross"];

  toggle() {
    super.toggle(); // Call the toggle method from stimulus-reveal-controller
    this.toggleIcons();
  }

  toggleIcons() {
    this.burgerTarget.classList.toggle("hidden");
    this.crossTarget.classList.toggle("hidden");
  }
}