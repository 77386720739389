// app/javascript/controllers/checkout_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  redirectToCheckout(event) {
    event.preventDefault(); // Prevent the default link behavior
    // Check if the app is embedded in an iframe
    if (window !== window.top) {
      // Notify the user they are being redirected
      const userConfirmed = window.confirm("You will be redirected to a secure payment platform. Do you wish to proceed?");
      
      // If the user clicks "OK", proceed with the redirect
      if (userConfirmed) {
        window.top.location.href = this.element.href;  // Redirect the top-level window
      }
    } else {
      // If not embedded, proceed as usual
      window.location.href = this.element.href;
    }
  }
}