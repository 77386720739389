// app/javascript/controllers/collapse_controller.js

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["menu", "caret"]
  static values = { initialOpen: Boolean } // Add a value for initial open state

  connect() {
    if (this.initialOpenValue) {
      this.open() // Initialize in open state if true
    }
  }

  toggle(e) {
    e.preventDefault();
    this.isOpen() ? this.close() : this.open();
  }

  open() {
    this.menuTarget.style.maxHeight = this.menuTarget.scrollHeight + "px";
    this.caretTarget.classList.add("rotate-180");
  }

  close() {
    this.menuTarget.style.maxHeight = "0px";
    this.caretTarget.classList.remove("rotate-180");
  }

  isOpen() {
    return this.menuTarget.style.maxHeight !== "0px" && this.menuTarget.style.maxHeight !== "";
  }
}
