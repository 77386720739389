import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    "kind",
    "product"
  ]

  connect() {
   this.productSelector = document.getElementById('organization_credit_product_id')
   this.handleKindUI()
  }

  handleKindUI(){
    this.organizationCreditKindSelector = document.getElementById('organization_credit_kind')
    this.handleDisplayProductSelector();
    this.organizationCreditKindSelector.selectize.on('change', () => this.handleDisplayProductSelector());
  }

  handleDisplayProductSelector(){
    let value = this.organizationCreditKindSelector.value
    if (value == 'universal'){
      this.productSelector.selectize.clear();
      this.productTarget.classList.add('hidden')
    } else {
      this.productTarget.classList.remove('hidden')
    }
  }



}
