// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import { Turbo } from "@hotwired/turbo-rails"
import 'controllers';
import defaultTheme from 'medium-editor/dist/css/themes/default.min.css';
import 'medium-editor/dist/css/medium-editor.min.css';
import 'shepherd.js/dist/css/shepherd.css';
import 'flag-icon-css/css/flag-icons.min.css'
import * as editor from './mediumEditor';
import ContentEditable from './contentEditable';
import LazyLoad from "vanilla-lazyload";

document.addEventListener("DOMContentLoaded", function (event) {

  history.scrollRestoration = 'manual'
  // lazyloading images
  window.lazyLoadInstance = new LazyLoad({});
  // editable images
  new ContentEditable('image').init();
  // editable content
  new ContentEditable('text').init();
});

// require("trix")
// require("@rails/actiontext")
