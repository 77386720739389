import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["number", "total"]
  static values = {
    currency: String,
    unitPrice: Number,
    priceTiers: Array
  }


  increment() {
    event.preventDefault()
    const maxQuantity = this.findMaxQuantity()
    const currentQuantity = parseInt(this.numberTarget.value)
    
    if (currentQuantity < maxQuantity) {
      this.numberTarget.value = currentQuantity + 1
      this.updateTotal()
    }
  }

  decrement() {
    event.preventDefault()
    if (this.numberTarget.value > 0) {
      this.numberTarget.value = parseInt(this.numberTarget.value) - 1
      this.updateTotal()
    }
  }

  handleDirectInput(event) {
    const input = event.target;
    const maxQuantity = this.findMaxQuantity();
    const value = parseInt(input.value);

    // Validate the input and enforce limits
    if (isNaN(value) || value < 0) {
      this.numberTarget.value = 0;
    } else if (value > maxQuantity) {
      this.numberTarget.value = maxQuantity;
    }

    this.updateTotal();
  }

  findMaxQuantity() {
    if (this.priceTiersValue.length === 0) {
      return Infinity
    }

    const maxTiers = this.priceTiersValue.filter(tier => tier.max === null)
    if (maxTiers.length > 0) {
      return Infinity
    }

    const validTiers = this.priceTiersValue.filter(tier => tier.max !== null)
    const maxTier = validTiers.reduce((max, tier) => tier.max > max ? tier.max : max, 0)
    return maxTier
  }

  updateTotal() {
    const quantity = parseInt(this.numberTarget.value)
    const tier = this.findPriceTier(quantity)
    const pricePerUnit = tier ? tier.price_without_tax_cents : this.unitPriceValue
    const totalCents = pricePerUnit * quantity
    this.totalTarget.innerHTML = `&nbsp;${this.priceWithCurrencyInUnits(totalCents, this.currencyValue)}`;
  }

  findPriceTier(quantity) {
    return this.priceTiersValue.find(tier => quantity >= tier.min && quantity <= tier.max)
  }


  priceWithCurrencyInUnits(total_cents, currency){
    var element = document.querySelector('[data-locale]'); // Gets the first matching element
    var locale = element.dataset.locale;
    var price = parseFloat(total_cents) / 100.0
    var formattedPrice = price.toLocaleString(locale, { style: 'currency', currency: currency });

    return formattedPrice;
  }
}