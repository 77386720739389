import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'banner',
    'cookieOptions',
    'baseCookieChoice',
    'customCookieSelection'
  ]

  refuseAll(){
    this._setCookiePrefAndCloseBanner('necessary')
  }

  acceptAll(){
    this._setCookiePrefAndCloseBanner('all')
  }

  setCustomCookies(){
    let selections = Array.from(this.customCookieSelectionTargets).filter(cb => cb.checked).map(e => e.value)
    this._setCookiePrefAndCloseBanner(selections)
  }

  showCookieOptions(){
    this.baseCookieChoiceTarget.classList.add('hidden');
    this.cookieOptionsTarget.classList.remove("hidden");
  }

  _setCookiePrefAndCloseBanner(cookiePref) {
    this._setCookiePreferences(cookiePref)
    this._fadeOutBanner()
  }

  _fadeOutBanner() {
    this.bannerTarget.classList.remove("opacity-100")
    this.bannerTarget.classList.add("opacity-0")
  }

  _setCookiePreferences(cookiePref) {
    // Create cookie for the banner
    let d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    let expires = `expires=${d.toUTCString()};`
    document.cookie = `_yunoo-cookie-preferences=${cookiePref};${expires};path=/;`
  }
}
