const spinnerTemplate = () => {
  return "<div id='spinner' class='bg-black opacity-75 animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center md:inset-0 h-modal sm:h-full' style='background-color: rgba(0, 0, 0, 0.8); z-index: 9999;'><i class='fas fa-spinner fa-spin text-6xl text-white font-bold'></i></div>"
}

const errorModalTemplate = () => {
  return `<div class="modal relative px-4 w-full max-w-2xl h-full md:h-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div class="w-full">
            <div class="flex justify-between items-start p-5 rounded-t">
              <div class="w-full-0">
                <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                  An error occured, please try again later
                </h3>
              </div>
              <i class="close-modal fas fa-times text-lg close-modal cursor-pointer"></i>
            </div>
          </div>
        </div>
      </div>`
}

export { errorModalTemplate, spinnerTemplate };
