import { Controller } from '@hotwired/stimulus';
import { load } from "@rails/ujs";

export default class extends Controller {
  static targets = ["address", "city", "postalCode", "country"]

  connect() {
    if (typeof(google) != "undefined") {
      this.initAutocomplete();
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, { types: ['address'] });
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    this.setAddressFields(place);
  }

  setAddressFields(place) {
    // Clear existing values
    this.addressTarget.value = '';
    this.cityTarget.value = '';
    this.postalCodeTarget.value = '';
    this.countryTarget.value = '';

    let streetAddress = '';

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          streetAddress += component.long_name;
          break;
        case "route":
          streetAddress += (streetAddress ? ' ' : '') + component.long_name;
          break;
        case "locality": // City
          this.cityTarget.value = component.long_name;
          break;
        case "postal_code": // Postal Code
          this.postalCodeTarget.value = component.long_name;
          break;
        case "country": // Country
          this.countryTarget.value = component.short_name;
          break;
      }
    }

    this.addressTarget.value = streetAddress;
  }
}
