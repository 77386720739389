import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "kind",
    "path",
    "parent",
    "tabName",
  ]

  connect() {
   this.handleKindUI()
  }

  handleKindUI(){
    this.customHeaderKindSelector = document.getElementById('rise_up_custom_header_kind')
    this.handleDisplaycustomHeaderKindUI()
    this.customHeaderKindSelector.selectize.on('change', () => this.handleDisplaycustomHeaderKindUI());
  }

  handleDisplaycustomHeaderKindUI(){
    this.handleDisplayPathSelector();
    this.handleDisplayParentSelector();
  }

  handleDisplayPathSelector(){
    let value = this.customHeaderKindSelector.value
    if (value == 'parent'){
      this.pathTarget.classList.add('hidden')
    } else {
      // if path target exists
      if (this.hasPathTarget){
        this.pathTarget.classList.remove('hidden')
      }
    }
  }

  handleDisplayParentSelector(){
    let value = this.customHeaderKindSelector.value
    if (value == 'parent'){
      if (this.hasParentTarget){
        this.parentTarget.classList.add('hidden')
      }
    } else if (value == 'standalone'){
      if (this.hasParentTarget){
        this.parentTarget.classList.add('hidden')
      }
    }
    else {
      // if parent target exists
      if (this.hasParentTarget){
        this.parentTarget.classList.remove('hidden')
      }
    }
  }





}
