import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["selectAllCheckbox"]
  static values = {
    checkoxesTargetSelector: String,
    activeElementId: String,
    activeClass: String
  }

  selectAll(){
    const checked = this.selectAllCheckboxTarget.checked;
    let checkboxes = document.querySelectorAll(`input[type=checkbox]${this.checkoxesTargetSelectorValue}`)
    if (checked) {
      checkboxes.forEach((cb) => { cb.checked = true; });
    } else {
      checkboxes.forEach((cb) => { cb.checked = false; });
    }
  }
}
