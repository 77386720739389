import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["sidebar", "overlay"]

  // Toggle sidebar visibility
  toggleSidebar() {
    if (this.sidebarTarget.classList.contains('-translate-x-full')) {
      this.showSidebar()
    } else {
      this.hideSidebar()
    }
  }

  // Show sidebar and overlay
  showSidebar() {
    this.sidebarTarget.classList.remove('-translate-x-full')  // Make the sidebar visible
    this.sidebarTarget.classList.add('translate-x-0')         // Slide in
    this.overlayTarget.classList.remove('hidden')             // Show overlay
  }

  // Hide sidebar and overlay
  hideSidebar() {
    this.sidebarTarget.classList.add('-translate-x-full')      // Hide the sidebar
    this.sidebarTarget.classList.remove('translate-x-0')       // Slide out
    this.overlayTarget.classList.add('hidden')                 // Hide overlay
  }
}
