import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    params: Object,
    targetUrl: String,
  }

  addEventToDatalayer(){
    if (this._shouldPushEventToDatalayer()){
       this._resetDatalayer();
       this._logEvent();
       this._pushEventToDatalayer();
    }
  }

  _pushEventToDatalayer(){
    window.dataLayer.push({
      ...this.paramsValue,
      ...this._baseParams()
    });
  }

  _logEvent(){
    console.log('datalayer front end event :',{
      ...this.paramsValue,
      ...this._baseParams()
    })
  }

  _resetDatalayer(){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
  }

  _baseParams(){
    return {
      eventCallback: function() {
        console.log('in eventCallback')
        if (window.Turbo) {
          return window.Turbo.visit(this.targetUrlValue);
        } else {
          widow.location = this.targetUrlValue
        }
      },
      eventTimeout: 2000
    }
  }

  _shouldPushEventToDatalayer(){
    return (this._isProductionEnv() || this._isStagingDemoAccount()) && !this._isPageReload()
  }

  _isStagingDemoAccount(){
    return window.location.host.split('.')[0] == 'demo' && this._railsEnv() == 'staging';
  }

  _isPageReload(){
    return document.referrer == window.location.href;
  }

  _railsEnv(){
    return document.body.dataset.env;
  }

  _isProductionEnv(){
    return this._railsEnv() == 'production';
  }
}
