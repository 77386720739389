import { Controller } from '@hotwired/stimulus';
import { spinnerTemplate } from 'packs/modal.js';

export default class extends Controller {

  showSpinner(){
    this.element.insertAdjacentHTML(
      'afterend',
      spinnerTemplate()
    );
  }

  insertModal(event){
    const [data, status, xhr] = event.detail;
    document.getElementById('spinner').remove()
    this.element.insertAdjacentHTML(
      'afterend',
      xhr.response
    );
  }

  error(event){
    const [data, status, xhr] = event.detail;
    document.getElementById('spinner').remove()
    alert("Something went wrong, please try again later")
  }
}
