import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['expandable', 'caret']
  static values = {
    activeClass: String
  }

  expand(){
    if (this._expanded()) {
      delete this.element.dataset.expanding
      this._collapse()
    } else {
      this.element.dataset.expanding = 'true'
    }
    this._toggleAll()
  }

  toggle() {
    if (this.element.dataset.expanding == 'true') {
      delete this.element.dataset.expanding
      this._expand()
    }
  }

  _expanded() {
    return this.caretTarget.classList.contains(this.activeClassValue)
  }

  _expand() {
    this.caretTarget.classList.add(this.activeClassValue)
    this.expandableTarget.classList.remove("max-h-0")
    this.expandableTarget.classList.add("max-h-100")
  }

  _collapse() {
    this.caretTarget.classList.remove(this.activeClassValue)
    this.expandableTarget.classList.add("max-h-0")
    this.expandableTarget.classList.remove("max-h-100")
  }

  _toggleAll() {
    const event = new Event('toggle_all')
    window.dispatchEvent(event)
  }
}
