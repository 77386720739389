// app/javascript/controllers/line_items_controller.js
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js';

export default class extends NestedForm {
  // Define the targets used in the controller
  static targets = ["productSelect", "template", "target", "wrapper", "addButton", "submitButton", "companyInfo", "individualInfo"];


  // Connect method called when the controller is instantiated
  connect() {
    super.connect();
    // Parse the product data from the dataset
    this.products = JSON.parse(this.element.dataset.products);
    this.updateProductOptions();
    // Initialize event listeners on the product selects
    this.productSelectTargets.forEach(select => {
      select.addEventListener('change', this.checkAddButtonState.bind(this));
    });
    // Initially check the state of the add button
    this.checkAddButtonState();
    this.checkSubmitButtonState();
    this.initializeLineItems();
    this.initializeBillingInformations();
  }

  toggle(event) {
    // Get the value of the selected radio button
    let selectedValue;
    if (event) {
      selectedValue = event.target.value;
    } else {
      // Get the value of the currently checked radio button
      const checkedRadio = this.element.querySelector('input[name="invoice[billable_kind]"]:checked');
      selectedValue = checkedRadio ? checkedRadio.value : null;
    }
    // Show or hide the sections based on the selected value
    if (selectedValue === "individual") {
      this.individualInfoTarget.classList.remove("hidden");
      this.companyInfoTarget.classList.add("hidden");
    } else if (selectedValue === "company") {
      this.individualInfoTarget.classList.add("hidden");
      this.companyInfoTarget.classList.remove("hidden");
    }
  }

  initializeBillingInformations(){
    // Get the value of the currently checked radio button
    const checkedRadio = this.element.querySelector('input[name="invoice[billable_kind]"]:checked');
    const selectedValue = checkedRadio ? checkedRadio.value : null;
    this.toggle({ target: { value: selectedValue } });
  }

  initializeLineItems() {
    // if product_id in url params
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('product_id');
    if (productId) {
      // add lien item with product_id selected
      this.add({ target: this.addButtonTarget, preventDefault: () => {} });
      const productSelect =  this.productSelectTargets[0];
      productSelect.value = productId;
      productSelect.disabled = true; 
      this.addButtonTarget.classList.add("hidden"); // Hide the add button
    }
  }
  // Override the add method to update product options after adding a new item
  add(event) {
    if (this.productSelectTargets.length >= 1) { // Prevent more than one line item
      event.preventDefault();
      return;
    }
    // Prevent adding a new item if the add button is disabled
    if (this.addButtonTarget.classList.contains("disabled")) {
      event.preventDefault();
      return;
    }

    super.add(event);
    this.updateProductOptions();
    // Re-add the event listener for the new select
    const newSelect = this.productSelectTargets[this.productSelectTargets.length - 1];
    newSelect.addEventListener('change', this.checkAddButtonState.bind(this));
    if (this.productSelectTargets.length >= 1) {
      this.addButtonTarget.classList.add("hidden"); // Hide the add button if max items are added
    }
  }

  // Override the remove method to reintegrate removed options and update product options
  remove(event) {
    // Get the select element and its value from the removed item
    const selectElement = event.target.closest(".nested-form-wrapper").querySelector("select");
    const removedValue = selectElement ? selectElement.value : null;

    super.remove(event);
    // Reintegrate the removed option back to other selects
    if (removedValue) {
      this.reintegrateOption(removedValue);
    }
    this.updateProductOptions();
    this.checkAddButtonState();
    this.checkSubmitButtonState();
  }

  // Method to reintegrate removed options back into the other selects
  reintegrateOption(value) {
    this.productSelectTargets.forEach(select => {
      if (!Array.from(select.options).map(option => option.value).includes(value)) {
        const product = this.products.find(product => product.id.toString() === value);
        if (product) {
          const newOption = document.createElement("option");
          newOption.value = product.id;
          newOption.text = product.yunoo_title;
          select.add(newOption);
        }
      }
    });
  }

  // Method to update product options in all selects
  updateProductOptions() {
    if (this.productSelectTargets.length === 0) return; // Return early if there are no product selects

    // Get the IDs of all selected products
    const selectedProductIds = Array.from(this.productSelectTargets).map(select => select.value).filter(id => id);

    this.productSelectTargets.forEach(select => {
      const currentSelectedValue = select.value;
      const options = this.products.map(product => {
        const option = document.createElement("option");
        option.value = product.id;
        option.text = product.yunoo_title;
        return option;
      });

      select.innerHTML = '';  // Clear all existing options

      // Add a blank option
      const blankOption = document.createElement("option");
      blankOption.value = "";
      blankOption.text = "";
      select.add(blankOption);

      // Add back options that are not selected or are the currently selected value
      options.forEach(option => {
        if (!selectedProductIds.includes(option.value) || option.value === currentSelectedValue) {
          select.add(option);
        }
      });

      // Restore the previously selected value
      select.value = currentSelectedValue;
    });

    // Check the state of the add button
    this.checkAddButtonState();
    this.checkSubmitButtonState();
  }

  checkSubmitButtonState(){
    // return if no productSelectTargets
    if (this.productSelectTargets.length === 0) {
      this.submitButtonTarget.classList.add("disabled");
    } else {
      this.submitButtonTarget.classList.remove("disabled");
    }
  }
  // Method to check the state of the add button
  checkAddButtonState() {
    // return if no productSelectTargets
    if (this.productSelectTargets.length === 0) {
      this.addButtonTarget.classList.remove("disabled");
      return;
    }
    // Check if all selects have a selected value (not blank)
    const selection = this.productSelectTargets.every(select => select.value !== "");

    // Disable the add button if all selects have a selected value or if no options are available
    const allOptionsCount = this.products.length // exclude blank options

    if (allOptionsCount <= this.productSelectTargets.length || !selection) {
      this.addButtonTarget.classList.add("disabled");
    } else {
      this.addButtonTarget.classList.remove("disabled");
    }
  }
}
