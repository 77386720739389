import { Controller } from '@hotwired/stimulus';
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  connect() {
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update()
    } else  {
      window.lazyLoadInstance = new LazyLoad({});
    }
  }
}