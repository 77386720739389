import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["header", "expandable"]

  expand(){
    // add a configurable activeClass
    this.headerTarget.classList.toggle("active");
    if(this.headerTarget.classList.contains("active")) {
      this.expandableTarget.style.maxHeight = this.expandableTarget.scrollHeight + "px" ;
    }
    else {
      this.expandableTarget.style.maxHeight = 0;
    }
  }

}
