import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["display", "real"];

  connect() {
    this.update();
  }

  update() {
    const unitValue = this.displayTarget.value || '0';
    const cents = parseFloat(unitValue) * 100;
    this.realTarget.value = Math.round(cents).toString();
  }
}
