import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Reveal from 'stimulus-reveal-controller'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import { Tabs } from "tailwindcss-stimulus-components"
import ReadMore from 'stimulus-read-more'
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'
window.Stimulus = Application.start()
window.Stimulus.register('nested-form', NestedForm)
window.Stimulus.register('tabs', Tabs)
window.Stimulus.register('reveal', Reveal)
window.Stimulus.register('checkbox-select-all', CheckboxSelectAll)
window.Stimulus.register('read-more', ReadMore)
const context = require.context('.', true, /_controller\.js$/)
Stimulus.load(definitionsFromContext(context))
const maglevContext = require.context('./maglev/', true, /_controller\.js$/)
const magicFormsContext = require.context('./magic_forms/', true, /_controller\.js$/)
const yunooLmsContext = require.context('./yunoo_lms/', true, /_controller\.js$/)
Stimulus.load(definitionsFromContext(magicFormsContext))
Stimulus.load(definitionsFromContext(maglevContext))
Stimulus.load(definitionsFromContext(yunooLmsContext))
