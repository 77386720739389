import { Controller } from '@hotwired/stimulus';
import { enter, leave } from "el-transition";
export default class extends Controller {
  static targets = ['input', 'container', 'backdrop', 'form']

  connect(){
    document.addEventListener("click", (event) => {
      if (!this.inputTarget.contains(event.target) && !this.containerTarget.classList.contains('hidden') && this.backdropTarget.contains(event.target)) {
        leave(this.containerTarget)
      }
    });
  }

  show(){
    enter(this.containerTarget)
  }
}