import { Controller } from '@hotwired/stimulus';
import { toggle } from '../utils/transition'

export default class extends Controller {
  static targets = ['menu', 'backdrop', 'dialog']

  connect() {
    this.open = false
  }

  async toggle() {
    this.open = !this.open

    if (this.open)
      this.dialogTarget.classList.remove('hidden')

    await Promise.all([
      toggle(this.backdropTarget), 
      toggle(this.menuTarget)]
    )

    if (!this.open)
      this.dialogTarget.classList.add('hidden')
  }
}
