import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
export default class extends Controller {
  static targets = ["total", "form"]
  static values = {
    currency: String,
  }

  update(){
    Rails.ajax({
      url: "/reset-cart",
      type: "post",
      data: new FormData(this.formTarget),
      success: (data) => { this.updateFromServer(data); this.removeLoader(); },
      error: function(data) { alert('An error happen, try again later'); location.reload(); }
    })
    const body = document.body
    body.insertAdjacentHTML(
      'afterbegin',
      `<div class="absolute h-screen bottom-0 right-0 left-0 top-0 bg-black opacity-75 flex items-center justify-center" style="z-index: 100;" id="body-filter">\
        <i class="fas fa-spinner fa-spin text-6xl text-white font-bold" style="z-index: 150;"></i>\
      </div>\
    `
    );
  }

  updateFromServer(data){
    this.updateCartItems(data['cart'], data['currency'])
    this.updateCartTotal(data['total_without_tax_cents'], data['currency'])
  }

  updateCartTotal(totalCents, currency){
    const cartTotal = document.querySelector('[data-cart-target="total"]')
    cartTotal.textContent = this.priceWithCurrencyInUnits(totalCents, currency)
  }

  updateCartItems(items, currency){
    items.forEach((item) => {
      this.updateCartItem(item, currency);
    })
  }

  updateCartItem(item, currency) {
    const cartItem = document.querySelector(`[data-cart-item-product-id="${item['product_id']}"]`)
    const cartItemQuantity = cartItem.querySelector('[data-cart-item-target="quantity"]')
    cartItemQuantity.value = item['quantity']
    cartItem.querySelector('[data-cart-item-target="totalPrice"]').textContent = this.priceWithCurrencyInUnits(item['total_without_tax_cents'], currency);
    cartItem.querySelector('[data-cart-item-target="unitPrice"]').textContent = this.priceWithCurrencyInUnits(item['unit_price'], currency);
  }

  priceWithCurrencyInUnits(total_cents, currency){
    var element = document.querySelector('[data-locale]'); // Gets the first matching element
    var locale = element.dataset.locale;
    var price = parseFloat(total_cents) / 100.0
    var formattedPrice = price.toLocaleString(locale, { style: 'currency', currency: currency });
    
    return formattedPrice;
  }

  removeLoader(){
    document.getElementById('body-filter').remove()
  }

  // currencyMapping() {
  //   return  {
  //     'EUR': '€'
  //   }
  // }
  //
  // humanizedTotal(){
  //   return `${this.humanizedCurrency()} ${this.totalUnit()}`
  // }
  //
  // humanizedCurrency(){
  //   return this.currencyMapping()[this.currencyValue]
  // }
  //
  // totalUnit(){
  //   return this.totalCents() / 100.0
  // }
  //
  // totalCents(){
  //   return this.cartItemElements().map(
  //     cartItemTotalValueElement => parseFloat(cartItemTotalValueElement.dataset.cartItemTotalValue)
  //   ).reduce(
  //     (previousValue, currentValue) => previousValue + currentValue,
  //     0
  //   );
  // }
  //
  // cartItemElements(){
  //   return Array.from(document.querySelectorAll('[data-cart-item-total-value]'))
  // }
}
