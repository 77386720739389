import { Controller } from '@hotwired/stimulus';
import { toggle } from './utils/transition'

export default class extends Controller {
  static targets = ['backdrop', 'panel']
  static values = { name: String }

  connect() {
    this.open = false
    window.addEventListener('hashchange', this.hashchangeHandler.bind(this))
    this.hashchangeHandler() // open the modal at startup?
  }

  disconnect() {
    window.removeEventListener('hashchange', this.hashchangeHandler.bind(this))
  }

  async toggle() {
    this.open = !this.open

    if (this.open)
      this.element.classList.remove('hidden')

    await Promise.all([
      toggle(this.backdropTarget), 
      toggle(this.panelTarget)]
    )

    if (!this.open) {
      this.element.classList.add('hidden')
      window.location.hash = ''
    }
  }

  hashchangeHandler(event) {
    const url = event ? event.newURL : window.location.href
    if (url.endsWith(`#${this.nameValue}-modal`))
      this.toggle()
  }

  closeBackground(event) {
    event.stopPropagation() & event.preventDefault()

    var node = event.target
    while (node) {
      if (node === this.panelTarget) return
      node = node.parentNode
    }
    this.toggle()
  }

  closeWithKeyboard(event) {
    if (event.keyCode === 27 && this.open)
      this.toggle()
  }
}
