import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["banner"]

  reveal(){
    if (document.body.classList.contains('fixed')) return;
    if (window.scrollY > 700) {
      this.bannerTarget.classList.remove("opacity-0");
    } else {
      this.bannerTarget.classList.add('opacity-0');
    }
  }
}
