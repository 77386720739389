import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { text: String };

  connect() {
    if (this.hasTextValue) {
      this.insertIconAfterLabel();
    }
  }

  insertIconAfterLabel() {
    // Locate the label within the wrapping div
    const label = this.element.querySelector('label');
    if (label) {
      // Create the question mark icon
      const icon = document.createElement('i');
      icon.className = 'fas fa-question-circle text-[#8898aa] text-sm cursor-pointer ml-2';
      icon.setAttribute('data-action', 'mouseenter->tooltip#show mouseleave->tooltip#hide');

      // Append the icon next to the label
      label.insertAdjacentElement('afterend', icon);

      // Store tooltip text on the icon for tooltip display
      icon.dataset.tooltipText = this.textValue;
    }
  }

  show(event) {
    this.createTooltip(event.currentTarget);
  }

  hide() {
    if (this.tooltip) {
      this.tooltip.remove();
      this.tooltip = null;
    }
  }

  createTooltip(target) {
    if (!this.tooltip) {
      this.tooltip = document.createElement('div');
      this.tooltip.className = 'absolute bg-gray-800 text-white text-xs rounded py-1 px-2 z-10';
      this.tooltip.style.minWidth = '100px';
      this.tooltip.style.whiteSpace = 'nowrap';
      this.tooltip.innerText = target.dataset.tooltipText;
      document.body.appendChild(this.tooltip);
    }
  
    const { top, left, width } = target.getBoundingClientRect();
    const tooltipWidth = this.tooltip.offsetWidth;
    const tooltipHeight = this.tooltip.offsetHeight;
  
    // Position above and slightly to the right of the question mark icon
    this.tooltip.style.top = `${top + window.scrollY - tooltipHeight - 5}px`; // Above the icon
    this.tooltip.style.left = `${left + window.scrollX + width}px`; // Right-align

  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }
}
