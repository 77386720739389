import { Controller } from '@hotwired/stimulus';
import '@selectize/selectize/dist/js/selectize.min.js'
import '@selectize/selectize/dist/css/selectize.css'
export default class extends Controller {
  connect() {
    var selectizeItems = $("select").selectize({
      dropdownParent: "body"
    });
  }
}
