// progressive_reveal_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["step", "progressiveRevealNext"]

  showStep(index) {
    this.stepTargets.forEach((el, stepIndex) => {
      const isCompletedStep = stepIndex < index;
      el.classList.toggle("hidden", !isCompletedStep);
      // Additional logic for progressiveRevealNext targets...
    });
  }

  // Additional methods specific to progressive reveal...
}
