// app/javascript/controllers/multi_step_form_controller.js
import { Controller } from '@hotwired/stimulus';
import validate from "validate.js";
// what should it do ?
// - show the current step
// - hide or show the completed steps (depending on the step behavior)
// - hide or show the next button (depending on the step behavior)
// - hide or show the previous button (depending on the step behavior)
// - validate the current step
export default class extends Controller {
  formDataForReview = {};
  static targets = ["step", "progressiveRevealNext", "spinner"]
  static values = {
    progressBarIndicator: Boolean,
    showCompletedStepsNavigation: {
      type: Boolean, default: false
    },
    showCompletedSteps: { 
      type: Boolean, default: false 
    },
    saveToFormResults: {
      type: Boolean, default: false
    },
    asyncValidations: {
      type: Object, default: {}
    },
    validations: {
      type: Object, default: {}
    },
    formConfig: {
      type: Object, default: {}
    },
    objectType: String,
    currentLocale: String,
    sessionId: String,
  }

  initialize() {
    // Set initial values from URL parameters if configured
    if (this.formConfigValue.initialValuesFromParams) {
        this.setInitialValuesFromParams(); // Set initial values based on URL parameters
    }

    // Hide all steps initially, except the first one
    this.hideAllSteps();

    // Using an Immediately Invoked Function Expression (IIFE) to handle asynchronous logic within initialize
    // An IIFE is a function that runs as soon as it is defined. It is a design pattern also known as a Self-Executing Anonymous Function
    // Here, it's used to manage asynchronous operations while keeping the initialize method itself synchronous
    (async () => {
        // Check for the first step with an error. If found, this step should be shown first
        let stepToShow = this.findFirstErrorStepIndex();

        // If no error steps and configuration allows, find the first step requiring user action (not prefilled or invalid)
        if (stepToShow === -1 && this.formConfigValue.skipStepWithPrefilledParams) {
            // Await the async function to find the first step that requires action
            stepToShow = await this.findFirstStepRequiringAction();
        }

        // Default to the first step if no other conditions are met
        stepToShow = stepToShow !== -1 ? stepToShow : 0;

        // Show the determined step
        this.showStep(stepToShow);

        // Update navigation and progress bar according to the shown step
        this.updateNavigation(stepToShow);
        if (this.progressBarIndicatorValue) {
            this.updateProgressBar(stepToShow);
        }

        // Attach keydown event listeners to input fields in the current step for user interaction
        this.attachKeydownListeners();
    })();
  }


  /**
   * Asynchronously finds the first step that requires user action in the multi-step form.
   * 
   * A step requires action if:
   * 1. It is not prefilled with valid data.
   * 2. It is prefilled, but the prefilled data does not pass validation (sync or async).
   *
   * @returns {Promise<number>} A promise that resolves to the index of the first step requiring action, 
   *                            or -1 if all steps are valid and prefilled.
   */
  async findFirstStepRequiringAction() {
    // Extract the current form data into an object.
    const formData = new FormData(this.element);
    const formValues = Object.fromEntries(formData.entries());

    // Iterate through each step in the form to check for required actions.
    for (let i = 0; i < this.stepTargets.length; i++) {
      // Check if the current step is prefilled by comparing form values with URL parameters.
      if (!this.isStepPrefilled(i)) {
        // If the step is not prefilled, it requires action, hence return its index.
        return i;
      }

      // Retrieve the current step element from the form.
      const stepElement = this.stepTargets[i];
      // Extract the validation rules for the current step.
      const stepValidations = this.extractStepValidations(stepElement);

      // Perform synchronous (immediate) validation first.
      const syncErrors = this.performSyncValidation(formValues, stepValidations);
      // If there are any synchronous validation errors, return the step index.
      if (syncErrors) {
        return i;
      }

      // If no sync errors, perform async validation which may involve server-side checks.
      const asyncErrors = await this.performAsyncValidation(stepElement);
      debugger
      // If asynchronous validation results in errors, return the step index.
      if (Object.keys(asyncErrors).length > 0) {
        return i;
      }
    }

    // If all steps are valid and prefilled, return -1 indicating no action required.
    return -1;
  }

  // Checks if a step is both prefilled and valid.
  // A step is prefilled if all its fields have values.
  // A step is valid if it passes all defined validate.js validations.
  isStepValidAndPrefilled(stepIndex) {
    const stepElement = this.stepTargets[stepIndex];
    const formValues = this.extractFieldValues(stepElement);
    const stepValidations = this.extractStepValidations(stepElement);

    // Check if all fields in the step are prefilled
    const isPrefilled = Object.keys(formValues).every(key => formValues[key].trim() !== '');
    if (!isPrefilled) return false;

    // Check if prefilled fields pass validation
    const errors = validate(formValues, stepValidations, { fullMessages: false });
    return !errors || Object.keys(errors).length === 0;
  }

  // Method to set initial form values based on URL parameters
  setInitialValuesFromParams() {
    const urlParams = new URLSearchParams(window.location.search); // Parse URL parameters
    urlParams.forEach((value, key) => {
      const input = this.element.querySelector(`[name="${key}"]`); // Find input field by name
      if (input) input.value = value; // Set input value if the field exists
    });
  }

  // Method to skip steps with pre-filled values based on URL parameters
  skipPrefilledSteps() {
    const urlParams = new URLSearchParams(window.location.search); // Parse URL parameters
    let firstUnfilledStepIndex = 0;

    // Loop through steps to find the first step with unfilled fields
    this.stepTargets.some((step, index) => {
      const inputFields = step.querySelectorAll('input, select, textarea'); // Get all input fields in the step
      const isStepFilled = Array.from(inputFields).every(input => {
        return urlParams.has(input.name) && urlParams.get(input.name) === input.value;
      });

      if (!isStepFilled) {
        firstUnfilledStepIndex = index; // Update the index of the first unfilled step
        return true; // Stop the loop
      }
      return false;
    });

    this.showStep(firstUnfilledStepIndex); // Show the first unfilled step
  }


  attachKeydownListeners() {
    const currentStep = this.stepTargets.find(el => el.classList.contains("active"));
    if (currentStep) {
      currentStep.querySelectorAll("input, select, textarea").forEach(field => {
        field.addEventListener('keydown', this.handleKeydown.bind(this));
      });
    }
  }

  handleKeydown(event) {
    // Check if the Enter key was pressed
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default Enter key action
      this.next(); // Call the next method to move to the next step
    }
  }

  findFirstErrorStepIndex() {
    // Iterate through each step to find the first one with an error
    return this.stepTargets.findIndex(step => step.classList.contains('error'));
  }

  storeFormDataForReview(stepIndex) {
    const formData = new FormData(this.element);
    const formValues = Object.fromEntries(formData.entries());
  
    // Store the data for the current step
    this.formDataForReview[`step${stepIndex}`] = formValues;
  
    // Optionally, you can also handle any data transformation or aggregation here
  }

  /**
   * Updates the width of the progress bar based on the current step index.
   * @param {number} currentStepIndex - The index of the current step in the multi-step form.
   */
  updateProgressBar(currentStepIndex) {
    // Check if the progress bar indicator feature is enabled
    if (!this.progressBarIndicatorValue) return;

    // Calculate the total number of steps in the form
    const totalSteps = this.stepTargets.length;

    // Calculate the progress percentage. 
    // The '+ 1' ensures that progress starts from the first step (not zero-indexed for user display).
    const progressPercentage = ((currentStepIndex + 1) / totalSteps) * 100;

    // Select the progress bar element from the DOM
    const progressBar = document.querySelector('.progress-bar');

    // If the progress bar element exists, update its width to reflect the current progress
    if (progressBar) {
      progressBar.style.width = `${progressPercentage}%`;
    }
  }

  hideAllSteps() {
    this.stepTargets.forEach((el) => {
      el.classList.add("hidden");
    });
    this.stepTargets.forEach((el) => {
      el.classList.remove("active");
    });
  }

 /**
 * Shows a specific step in the multi-step form.
 * @param {number} index - The index of the step to be shown.
 */
  showStep(index) {
    // Check if the step at the given index exists
    if (this.stepTargets[index]) {
      this.stepTargets.forEach((el, stepIndex) => {
        const isLastStep = stepIndex === this.stepTargets.length - 1;
        const isCurrentStep = stepIndex === index;
        const isCompletedStep = stepIndex < index;

        // Hide all steps by default
        el.classList.add("hidden");
        el.classList.remove("active");

        // Show the current step and add 'active' class
        if (isCurrentStep) {
          el.classList.remove("hidden");
          el.classList.add("active");
          
          // If the current step is the review step, populate it with collected data
          if (this.isReviewStep(index)) {
            this.populateReviewStep();
          }
        }

        // Show completed steps if configured to do so
        if (isCompletedStep && this.showCompletedStepsValue) {
          el.classList.remove("hidden");
        }

        // Handle the visibility of the 'Next' button
        const nextButton = el.querySelector('[data-multi-step-form-target="progressiveRevealNext"]');
        if (nextButton) {
          // Show the 'Next' button only if it is the current step and not the last step
          nextButton.classList.toggle("hidden", !isCurrentStep || isLastStep);
        }
      });

      // Update the navigation to reflect the current step
      this.updateNavigation(index);
    }
    // Attach keydown event listeners to the new step
    this.attachKeydownListeners();
  }

  /**
   * Determines if the step at the given index is the review step.
   * @param {number} index - The index of the step to check.
   * @returns {boolean} True if the step is the review step, false otherwise.
   */
  isReviewStep(index) {
    const stepConfig = this.getStepConfig(index);
    return stepConfig && stepConfig.is_review_step;
  }

  /**
   * Determines if a step in the multi-step form is prefilled based on URL parameters.
   * A step is considered prefilled if all of its input fields (if any) match the corresponding URL parameters.
   *
   * @param {number} stepIndex - The index of the step to check.
   * @returns {boolean} True if the step is prefilled, false otherwise.
   */
  isStepPrefilled(stepIndex) {
    // Retrieve the step element from the list of steps using the provided index.
    const step = this.stepTargets[stepIndex];
    // If the step element is not found, return false as it cannot be prefilled.
    if (!step) return false;

    // Select all input fields within the step, including text fields, select dropdowns, and textareas.
    const inputFields = step.querySelectorAll('input, select, textarea');
    // If there are no input fields in the step, it is not considered prefilled.
    if (inputFields.length === 0) {
      return false;
    }

    // Parse the URL parameters from the current page's URL.
    const urlParams = new URLSearchParams(window.location.search);

    // Check if each input field in the step has a corresponding URL parameter with a matching value.
    // The method 'every' ensures that all fields must meet this condition for the step to be considered prefilled.
    return Array.from(inputFields).every(input => {
      const paramName = input.name; // Get the name attribute of the input field.
      // Check if a URL parameter with the same name exists and if its value matches the input field's value.
      return urlParams.has(paramName) && urlParams.get(paramName) === input.value.trim();
    });
  }


  /**
   * Handles the action to move to the next step in the multi-step form.
   */
  async next() {
    event.preventDefault(); // Prevent the default form submission behavior

    // Find the index of the currently active step
    let currentIndex = this.stepTargets.findIndex(el => el.classList.contains("active"));

    // Store form data for review before moving to the next step
    this.storeFormDataForReview(currentIndex);
    // Retrieve the configuration for the current step
    let currentStepConfig = this.getStepConfig(currentIndex);

    // Validate the current step and store the result (true if valid, false otherwise)
    const stepIsValid = await this.validateStep(this.stepTargets[currentIndex], currentStepConfig);
    // Proceed only if the current step is valid
    if (stepIsValid) {
      // Check if the current step configuration requires saving data on step completion
      if (currentStepConfig.save_on_step_completion) {
        // Save the data for the current step
        this.saveStepData(currentIndex);
      }

      // Asynchronously find the index of the next valid step based on conditions
      let nextIndex = await this.findNextActionableStepIndex(currentIndex + 1);

      // Check if a valid next step is found
      if (nextIndex !== -1) {
        // Ensure the next step index is within the range of available steps
        if (currentIndex < this.stepTargets.length - 1) {
          // If configured to show completed steps, mark the current step as completed
          if (this.showCompletedStepsValue) {
            this.stepTargets[currentIndex].classList.add("completed");
          }

          // Update the progress bar to reflect the progress up to the next step
          this.updateProgressBar(nextIndex);

          // Show the next step
          this.showStep(nextIndex);
        }
      }
    }
  }


   /**
   * Handles the action to move to the previous step in the multi-step form.
   * It finds and shows the previous valid step based on the form's conditions.
   */
   previous() {
    event.preventDefault();

    // Find the index of the currently active step
    let currentIndex = this.stepTargets.findIndex(el => el.classList.contains("active"));

    // Check if there is a step before the current one
    if (currentIndex > 0) {
      // Find the index of the previous valid step
      let previousIndex = this.findPreviousValidStepIndex(currentIndex - 1);

      debugger
      // Skip prefilled steps if configured
      while (this.formConfigValue.skipStepWithPrefilledParams && this.isStepPrefilled(previousIndex) && previousIndex > 0) {
        previousIndex = this.findPreviousValidStepIndex(previousIndex - 1);
      }
      // If a valid previous step is found, show it
      if (previousIndex !== -1) {
        // Update the progress bar to reflect the progress up to the previous step
        this.updateProgressBar(previousIndex);

        // Show the previous step
        this.showStep(previousIndex);
      }
    }
  }


  saveStepData(stepIndex) {
    const formData = new FormData(this.element);
    formData.append('current_step', stepIndex);
    formData.append('session_id', this.sessionIdValue);
    this.saveToFormResults(formData);
  }


  /**
   * Finds the index of the previous valid step based on the current form data and step conditions.
   * @param {number} startIndex - The index from which to start checking backwards for the previous valid step.
   * @returns {number} The index of the previous valid step, or -1 if no valid step is found.
   */
  findPreviousValidStepIndex(startIndex) {
    // Extract the current form data into an object
    const formData = new FormData(this.element);
    const formValues = Object.fromEntries(formData.entries());

    // Iterate backwards through the steps starting from the given startIndex
    for (let i = startIndex; i >= 0; i--) {
      // Retrieve the configuration for the current step
      let stepConfig = this.getStepConfig(i);

      // Evaluate the condition for the current step, if any
      if (this.evaluateCondition(stepConfig.conditions, formValues)) {
        // If the condition is met (or if there is no condition), return the current index
        return i;
      }
    }

    // If no valid previous step is found, return -1
    return -1;
  }

  // hasRecaptcha(stepElement) {
  //   return stepElement.querySelector('.g-recaptcha') != null;
  // }
  
  // validateRecaptcha() {
  //   return grecaptcha.getResponse().length !== 0;
  // }
  
  // getRecaptchaErrorMessage() {
  //   return this.recaptchaErrorMessagesValue[this.currentLocaleValue]
  // }

  async validateStep(stepElement, stepConfig) {
    //  // Special handling for reCAPTCHA
    // if (this.hasRecaptcha(stepElement) && !this.validateRecaptcha()) {
    //   // Add an error for reCAPTCHA
    //   const recaptchaError = { recaptcha: [this.getRecaptchaErrorMessage()] };
    //   this.displayErrors(stepElement, recaptchaError);
    //   return false;
    // }
    const formValues = this.extractFieldValues(stepElement);
    const stepValidations = this.extractStepValidations(stepElement);
  
    // Perform synchronous validation first
    const syncErrors = this.performSyncValidation(formValues, stepValidations);
    this.updateValidationUI(stepElement, syncErrors); // Update UI for sync validation
    if (syncErrors) {
      this.displayErrors(stepElement, syncErrors);
      return false;
    }
  
    // Perform async validation if applicable
    const asyncErrors = await this.performAsyncValidation(stepElement);
    this.displayErrors(stepElement, asyncErrors);
  
    // Update UI based on validation results
    this.updateValidationUI(stepElement, asyncErrors);
  
    // Return true if no errors, false otherwise
    return Object.keys(asyncErrors).length === 0;
  }
  
  extractFieldValues(stepElement) {
    const formValues = {};
    stepElement.querySelectorAll("input, select, textarea").forEach(field => {
      formValues[field.name] = field.value;
    });
    return formValues;
  }
  
  extractStepValidations(stepElement) {
    const stepValidations = {};
    stepElement.querySelectorAll("input, select, textarea").forEach(field => {
      if (this.validationsValue[field.name]) {
        stepValidations[field.name] = this.validationsValue[field.name];
      }
    });
    return stepValidations;
  }
  
  performSyncValidation(formValues, stepValidations) {
    return validate(formValues, stepValidations, { fullMessages: false });
  }
  
  async performAsyncValidation(stepElement) {
    let errors = {};
    const asyncValidationFields = this.getStepAsyncValidationFields(stepElement);
    for (const field of asyncValidationFields) {
      const isValid = await this.validateFieldAsync(field);
      if (!isValid) {
        errors[field.name] = [this.getAsyncValidationForField(field).message[this.currentLocaleValue]];
      }
    }
    return errors;
  }
  
  updateValidationUI(stepElement, errors) {
    stepElement.querySelectorAll("input, select, textarea").forEach(field => {
      const checkmark = field.parentElement.parentElement.querySelector('[data-multi-step-form-target="checkmark"]');
      const cross = field.parentElement.parentElement.querySelector('[data-multi-step-form-target="cross"]');
      // Check if there are no errors for this field or if errors is undefined
      const fieldHasNoErrors = !errors || !errors[field.name];

      if (fieldHasNoErrors) {
        if (checkmark) checkmark.classList.remove('hidden');
        if (cross) cross.classList.add('hidden');
        field.classList.add('success');
      } else {
        if (checkmark) checkmark.classList.add('hidden');
        if (cross) cross.classList.remove('hidden');
        field.classList.remove('success');
      }
    });
  }

  async validateFieldAsync(field) {
    const asyncValidation = this.getAsyncValidationForField(field)
    const container = field.closest('.grid');

    // Find the spinner and checkmark elements related to this field
    // They are now direct siblings of the input field
    const spinner = field.parentElement.nextElementSibling; // Assuming spinner is right after the inputElement
    const checkmark = spinner.nextElementSibling; // Assuming checkmark is right after the spinner


    // Show spinner and hide checkmark
    spinner.classList.remove('hidden');

    checkmark.classList.add('hidden');

    const response = await fetch(`/${asyncValidation.endpoint}?${field.name}=${encodeURIComponent(field.value)}`);
    const data = await response.json();
    // Hide spinner
    spinner.classList.add('hidden');

    if (data.isValid) {
      checkmark.classList.remove('hidden');
    }

    return data.isValid;
  }

  getAsyncValidationForField(field) {
    const asyncValidations = this.asyncValidationsValue;
    return asyncValidations[field.name];
  }

  getFieldName(name) {
    if (this.objectTypeValue === undefined) {
       return `[data[${name}]]`
    } else {
      return `${this.objectTypeValue}[${name}]`
    }
  }

  // retrieves the fields for the current step that have async validations
  getStepAsyncValidationFields(stepElement) {
    const asyncFields = [];
    const asyncValidations = this.asyncValidationsValue;
  
    // Iterate over each key in the asyncValidations object
    for (const fieldName in asyncValidations) {
      if (asyncValidations.hasOwnProperty(fieldName)) {
        // Find the field within the stepElement with the matching name
        const field = stepElement.querySelector(`[name="${fieldName}"]`);
  
        // If the field is found and it's within the current step, add it to the list
        if (field) {
          asyncFields.push(field);
        }
      }
    }
  
    return asyncFields;
  }
  

  // Method to localize validation messages
  // not used yet
  localizeValidationMessages(errors) {
    if (errors) {
      Object.keys(errors).forEach(key => {
        errors[key] = errors[key].map(errorKey => 
          this.validationsValue[key][errorKey][this.currentLocaleValue] || errorKey
        );
      });
    }
    return errors;
  }
  // Method to validate all steps (the entire form)
  validateAll() {
    const formData = new FormData(this.element);
    const formValues = Object.fromEntries(formData.entries());
  
    // Performing validation for all form fields
    let errors = validate(formValues, this.validationsValue, { fullMessages: false });
    
    // Check if reCAPTCHA is present and validate it
    // if (this.hasRecaptcha(this.element) && !this.validateRecaptcha()) {
    //   const recaptchaError = { recaptcha: [this.getRecaptchaErrorMessage()] };
    //   errors = { ...errors, ...recaptchaError };
    // }

    this.displayErrors(this.element, errors);
  
    // Update UI based on validation results
    this.updateValidationUI(this.element, errors);
  
    // Return true if no errors, false otherwise
    return !errors;
  }

    // In your multi-step-form Stimulus controller
  submitForm(event) {
    event.preventDefault(); // Prevent the form from submitting immediately

    // Perform validation on all steps
    const isValid = this.validateAll();
    if (isValid) {
      if (this.saveToFormResultsValue) {
        const formData = new FormData(this.element); 
        this.saveToFormResults(formData);
        // Append form_result_id to the form
        this.appendHiddenInput(this.element, 'form_result_id', this.formResultId);
      }

      // If validation passes, submit the form
      this.element.submit();
    } else {
      // If validation fails, do not submit the form
      // You can add additional logic here if needed, such as displaying a message
    }
  }

  appendHiddenInput(form, name, value) {
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', name);
    hiddenInput.setAttribute('value', value);
    form.appendChild(hiddenInput);
  }

  saveToFormResults(formData) {
    // Implement AJAX request to FormResult controller
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    console.log([...formData]); // This will log the form data as an array of [key, value] pairs
    const data = { form_result: { 
        data: Object.fromEntries(formData) 
     } };

    fetch('/magic_forms/form_results', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken  // Add CSRF token to the request headers
        // Include CSRF token header if necessary
      },
      body: JSON.stringify(data),
      // Additional headers or configurations as necessary
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Parse the response body to JSON
    }).then(data => {
      this.formResultId = data.form_result_id; // Set the formResultId from the parsed JSON
      // Handle response
    }).catch(error => {
      // Handle error
    });
  }

  // Method to display validation errors
  displayErrors(element, errors) {
    // Clear previous errors
    element.querySelectorAll('.error-message').forEach(el => el.remove());
    element.querySelectorAll('.error').forEach(el => el.classList.remove('error'));

    // Display new errors, if any
    if (errors) {
      Object.keys(errors).forEach(key => {
        // if (key === 'recaptcha') {
        //   // Special handling for reCAPTCHA errors
        //   this.displayRecaptchaError(errors[key]);
        // } else {
          // Handling for regular input fields
          const inputElement = element.querySelector(`[name="${key}"]`);
          if (inputElement) {
            const parentDiv = inputElement.parentElement;
            const errorMessage = this.createErrorMessage(errors[key]);
            parentDiv.appendChild(errorMessage);
            inputElement.classList.add('error');
          }
        // }
      });
    }
  }

  createErrorMessage(errorMessages) {
    const errorMessage = document.createElement('div');
    errorMessage.classList.add('error-message');
    errorMessage.textContent = errorMessages.map(errorKey => 
      errorKey[this.currentLocaleValue] || errorKey
    ).join(', ');
    return errorMessage;
  }

  // displayRecaptchaError(errorMessages) {
  //   const recaptchaElement = this.element.querySelector('.g-recaptcha');
  //   if (recaptchaElement) {
  //     const errorMessage = this.createErrorMessage(errorMessages);
  //     recaptchaElement.after(errorMessage); // Place error message after the reCAPTCHA widget
  //     recaptchaElement.classList.add('error');
  //   }
  // }

  getStepConfig(index) {
    const stepConfig = this.formConfigValue.steps[index];
    return stepConfig || {};
  }

  updateNavigation(activeIndex) {
    const navLinks = document.querySelectorAll('nav[aria-label="Progress"] a');
    navLinks.forEach((link, index) => {
      let isCurrentStep = activeIndex === index;
      let isCompletedStep = index < activeIndex;
      link.classList.remove("active");
      if (isCompletedStep && this.showCompletedStepsNavigationValue) {
        link.classList.add("completed");
      }
      if (isCurrentStep) {
        link.classList.add("active");
      }
    });
  }

  /**
   * Asynchronously finds the index of the next actionable step based on the current form data, step conditions,
   * and including asynchronous validations if configured. This includes steps that are not prefilled or 
   * prefilled but invalid.
   *
   * @param {number} startIndex - The index from which to start checking for the next actionable step.
   * @returns {Promise<number>} A promise that resolves to the index of the next actionable step, or -1 if all steps are valid and prefilled.
   */
  async findNextActionableStepIndex(startIndex) {
    const formData = new FormData(this.element);
    const formValues = Object.fromEntries(formData.entries());

    for (let i = startIndex; i < this.stepTargets.length; i++) {
      let stepConfig = this.getStepConfig(i);

      // Evaluate the condition for the current step, if any
      if (this.evaluateCondition(stepConfig.conditions, formValues)) {
        // If skipping prefilled steps is enabled, check the validity of the step
        if (this.formConfigValue.skipStepWithPrefilledParams && this.isStepPrefilled(i)) {
          const stepValidations = this.extractStepValidations(this.stepTargets[i]);
          const syncErrors = validate(formValues, stepValidations, { fullMessages: false });
          const asyncErrors = await this.performAsyncValidation(this.stepTargets[i]);

          // Combine synchronous and asynchronous errors
          const combinedErrors = { ...syncErrors, ...asyncErrors };

          // If there are any validation errors (sync or async), this step should not be skipped
          if (Object.keys(combinedErrors).length > 0) {
            this.displayErrors(this.stepTargets[i], combinedErrors);
            return i;
          }

          // Continue to the next iteration if the step is prefilled and valid
          continue;
        }

        // Return the index of the first step that either isn't prefilled or is invalid
        return i;
      }
    }

    // If no actionable next step is found, return -1
    return -1;
  }




  /**
 * Evaluates the condition for a form step based on the current form data.
 * @param {Object} condition - The condition object to evaluate.
 * @param {Object} formData - The current form data as an object.
 * @returns {boolean} True if the condition is met, false otherwise.
 */
  evaluateCondition(condition, formData) {
    // If there's no condition, return true (indicating the step is always valid)
    if (!condition) return true;

    // If the condition has a 'rules' array, evaluate each rule
    if (condition.rules && Array.isArray(condition.rules)) {
      // Assuming default logical operator is 'and' if not specified
      const operator = condition.operator || 'and';
      if (operator === 'and') {
        // All rules must be true for 'and' operator
        return condition.rules.every(rule => this.evaluateRule(rule, formData));
      } else if (operator === 'or') {
        // At least one rule must be true for 'or' operator
        return condition.rules.some(rule => this.evaluateRule(rule, formData));
      }
    }

    // Default to false for unrecognized conditions
    return false;
  }

  /**
   * Evaluates a single rule within a condition.
   * @param {Object} rule - The rule object to evaluate.
   * @param {Object} formData - The current form data as an object.
   * @returns {boolean} True if the rule is met, false otherwise.
   */
  evaluateRule(rule, formData) {
    // Evaluate a single rule by comparing the form data's value for the specified field with the rule's value
    return formData[this.getFieldName(rule.field)] === rule.value;
  }
  
}
