import { Controller } from '@hotwired/stimulus';
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle'

export default class extends Controller {
  static targets = ["buttonNext", "buttonPrev", "pagination", "container", "slide"]

  connect() {
    this.init()
    this.update()
  }

  init() {
    this.instance = new Swiper(this.container, this.config)
  }

  // BUG: When resizying window, the slide width is not reset,
  // making weird things on a few breakpoints.
  // so we manually update the slider
  update() {
    this.slideTargets.forEach((slide) => {
      slide.removeAttribute('style')
    })
    this.instance.update()
  }

  breakpointsFor(input) {
    let array = input.split('/')

    return {
      xs: array[0],
      sm: array[1],
      md: array[2],
      lg: array[3],
      xl: array[4],
    }
  }

  get container() {
    if (this.hasContainerTarget) return this.containerTarget

    return this.element
  }

  get config() {
    let base = {
      watchOverflow: true,
      breakpoints: {
        640: {},
        768: {},
        1024: {},
        1280: {}
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      }
    }

    // if (this.hasButtonNextTarget && this.hasButtonPrevTarget) {
    //   base.navigation = {
    //     nextEl: this.buttonNextTarget,
    //     prevEl: this.buttonPrevTarget
    //   }
    // }

    // if (this.hasPaginationTarget) {
      base.pagination = {
        el: this.paginationTarget,
        type: "bullets",
        clickable: true
      }
    // }

    if (this.data.has('responsiveSlidesPerView')) {
      let breakpoints = this.breakpointsFor(this.data.get('responsiveSlidesPerView'))

      base.slidesPerView = breakpoints.xs
      base.breakpoints["640"].slidesPerView = breakpoints.sm
      base.breakpoints["768"].slidesPerView = breakpoints.md
      base.breakpoints["1024"].slidesPerView = breakpoints.lg
      base.breakpoints["1280"].slidesPerView = breakpoints.xl
    }


    if (this.data.has('spaceBetween')) {
      base.spaceBetween = parseInt(this.data.get('spaceBetween'))
    } else {
      base.spaceBetween = 30
    }


    if (this.data.has('autoplay')) {
      base.autoplay = {
        delay: this.data.get('autoplay'),
        disableOnInteraction: true
      }
    }

    return base
  }
}
