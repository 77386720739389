import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ["container"]
  static values = {
    containerSelector: String,
    url: String,
    params: Object,
    reloadUi: { type: Boolean, default: false },
    uiLoaded: { type: Boolean, default: false }
  }

  fetchUI(){
    if (!this.reloadUiValue && this.uiLoadedValue) { return; }
    axios.post(this.urlValue, this.paramsValue)
    .then((response) => {
      this.uiLoadedValue = true
      this.containerTarget.innerHTML = response.data
    }).catch((err) => {
      this.uiLoadedValue = false
      this.containerTarget.innerHTML = `<p class='text-red-500'>${this.errorMessage(err.response.status)}</p>`
    })
  }

  errorMessage(status){
    switch (status) {
      case 401:
        return "You are not authorized to perform this action"
      default:
        return "Something went wrong, please try again later"
    }
  }
}
