// app/javascript/controllers/toastr_controller.js
import { Controller } from "@hotwired/stimulus";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default class extends Controller {
  static values = { type: String, message: String }

  connect() {
    if (this.hasTypeValue && this.hasMessageValue) {
      this.showToastr();
    }
  }

  showToastr() {
    toastr.options = {
      closeButton: true,
      progressBar: true,
      positionClass: "toast-top-right", // Customize position
      timeOut: "10000",                 // Extend display time to 10 seconds
      extendedTimeOut: "2000",          // Time after hover ends before Toastr disappears
      showDuration: "1000",              // Animation time for showing
      hideDuration: "1000",             // Animation time for hiding
    };

    toastr[this.typeValue](this.messageValue);
  }
}
