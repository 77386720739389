import { Controller } from '@hotwired/stimulus';
import Rails from "@rails/ujs";
import validate from 'validate.js';
export default class extends Controller {
  static targets = [
    'nextStepButton',
    'contactKindInput',
    'contactLevelInput',
    'finalStepContainer',
    'firstStepContainer',
    'backStepButton',
    'submitButton',
    'cpfForm',
    'input',
    'container'
  ]
  onPostSuccess(){
    // event is ajax success

    const [, , xhr] = event.detail;
    if (xhr.response.length > 0) {
      this.containerTarget.innerHTML = xhr.response;
      // this.confirmExpectedTransactionStepTarget.classList.remove('border-gray-200')
      // this.confirmExpectedTransactionStepTarget.classList.add('border-bg-primary')
      // this.confirmExpectedTransactionStepTarget.classList.remove('text-gray-500')
      // this.confirmExpectedTransactionStepTarget.classList.add('bg-primary-as-text')
      // return this.contentTarget.innerHTML = xhr.response
    }
  }
  showErrors(errors){
    for (let input of this.inputTargets){
      this.showErrorsForInput(input, errors[input.name])
    }
  }

  showErrorsForInput(input, errors){
    this.clearErrors(input)
    if (errors){
      input.classList.add('border-red-500')
      this.insertErrorMessages(input, errors)
    } else if (document.getElementById(`error_${input.name}`)) {
      input.classList.remove('border-red-500')
      document.getElementById(`error_${input.name}`).remove()
    }
  }

  insertErrorMessages(input, errors){
    var html = document.createElement('div')
    html.innerHTML = errors.join(' ')
    html.id = `error_${input.name}`
    html.className = 'text-sm text-red-500'
    input.after(html)
  }

  clearErrors(input){
    if (document.getElementById(`error_${input.name}`) != null) {
      document.getElementById(`error_${input.name}`).remove()
    }
  }

  constraints(){
    var constraints = {}
    for (let input of this.inputTargets){
      constraints[input.name] = JSON.parse(input.getAttribute('data-validate'))
    }
    return constraints;
  }

  validateFormOnSubmit(){
    return validate(this.cpfFormTarget, this.constraints())
  }

  currentInputConstraints(input){
    return Object.assign({}, { [input.name] : this.constraints()[input.name]})
  }


  firstStepErrors(){
    let allConstraints = this.constraints()
    const firstStepConstraints = ['ticket[contact_kind]', 'ticket[contact_level]']
      .filter(key => key in allConstraints) // line can be removed to make it inclusive
      .reduce((obj2, key) => (obj2[key] = allConstraints[key], obj2), {});
    return validate({
        'ticket[contact_kind]': this.inputTargets.find(el => el.id == 'ticket_contact_kind').value,
        'ticket[contact_level]': this.inputTargets.find(el => el.id == 'ticket_contact_level').value
      },
      firstStepConstraints
    )
  }

  letFormSubmission(){
    let errors = this.validateFormOnSubmit()
    if (errors){
      if (!this.submitButtonTarget.classList.contains('btn-gray-500')) {
        this.submitButtonTarget.classList.add('btn-gray-500')
        this.submitButtonTarget.classList.remove('btn-primary')
      }
      this.submitButtonTarget.disabled = true;
    }
    else {
      if (!this.submitButtonTarget.classList.contains('btn-primary')) {
        this.submitButtonTarget.classList.remove('btn-gray-500')
        this.submitButtonTarget.classList.add('btn-primary')
      }
      this.submitButtonTarget.disabled = false;
    }
  }

  validateCurrentInput(event){
    let currentInput = event.target;
    let currentInputErrors = validate(
      { [currentInput.name]: currentInput.value },
      this.currentInputConstraints(currentInput)
    )
    if (currentInputErrors){
      currentInput.classList.add('border-red-500')
      this.showErrorsForInput(currentInput, currentInputErrors[currentInput.name])
    } else {
      if (currentInput.classList.contains('border-red-500')){
        currentInput.classList.remove('border-red-500')
        document.getElementById(`error_${currentInput.name}`).remove()
      }
    }
    this.letFormSubmission()
  }

  // onFormSuccess(){
  //   debugger
  // }

  submitFirstStep(){
    let errors = this.firstStepErrors()
    if (errors != null) {
      this.showErrors(errors || {})
    } else {
      this._next()
    }
  }

  back(event) {
    this.backStepButtonTarget.classList.add('hidden')
    this.firstStepContainerTarget.classList.remove('hidden')
    this.finalStepContainerTarget.classList.add('hidden')
    this.nextStepButtonTarget.classList.remove('hidden')
    this.submitButtonTarget.classList.add('hidden')
  }

  _next() {
    this.backStepButtonTarget.classList.remove('hidden')
    this.firstStepContainerTarget.classList.add('hidden')
    this.finalStepContainerTarget.classList.remove('hidden')
    this.nextStepButtonTarget.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }
}
