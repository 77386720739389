import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {
    // Set the first tab as active by default
    this.showTab(0);
  }

  switchTab(event) {
    // Prevent default link behavior
    event.preventDefault();
    
    // Get the index of the clicked tab
    const tabIndex = this.tabTargets.indexOf(event.currentTarget);

    // Show the selected tab's content
    this.showTab(tabIndex);
  }

  showTab(index) {
    // Remove active state from all tabs and hide all contents
    this.tabTargets.forEach((tab, i) => {
      tab.classList.toggle("active", i === index);
      tab.classList.toggle("text-[#b2bbcb]", i !== index);
      tab.classList.toggle("border-b-riseup-primary", i === index);
    });

    // Show only the selected content
    this.contentTargets.forEach((content, i) => {
      content.classList.toggle("hidden", i !== index);
    });
  }
}
