import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['query', 'table']

  filter(){
    const searchTerm = this.queryTarget.value.toLowerCase();
    this.tableTarget.querySelectorAll('tbody tr.searchable').forEach((tr) => {
      if (!tr.textContent.toLowerCase().includes(searchTerm)){
        tr.classList.add('hidden')
      } else {
        tr.classList.remove('hidden')
      }
    })
  }
}
