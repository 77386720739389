import MediumEditor from 'medium-editor';
import AutoList from 'medium-editor-autolist';

const autolist = new AutoList();
// get all elements that are editable if we have an admin cookie
// add at the top right side a save button
document.addEventListener("DOMContentLoaded", function(event) {
  const editor = new MediumEditor('*[data-editablekind="text"]', {
      extensions: {
        'autolist': autolist
      },
      toolbar: {
          disableReturn: false,
          disableDoubleReturn: true,
          disableExtraSpaces: true,
          allowMultiParagraphSelection: false,
          buttons: ['bold', 'italic', 'underline', 'anchor', 'unorderedlist'],
          diffLeft: 0,
          diffTop: -10,
          firstButtonClass: 'medium-editor-button-first',
          lastButtonClass: 'medium-editor-button-last',
          relativeContainer: null,
          standardizeSelectionStart: false,
          static: false,
          /* options which only apply when static is true */
          align: 'center',
          sticky: false,
          updateOnEmptySelection: false
      }
  });
  editor.subscribe("editableKeydownEnter", (event, element) => {
     event.preventDefault()
     event.stopPropagation()
     MediumEditor.selection.getSelectionStart(editor.options.ownerDocument)
     MediumEditor.util.insertHTMLCommand(editor.options.ownerDocument, "<br><br>")
   })
})
