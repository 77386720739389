import { Controller } from "@hotwired/stimulus";
import Shepherd from "shepherd.js";

export default class extends Controller {
  static values = { 
    config: Object,
    savedStepIndex: {
      type: Number,
      default: 0
    },
    currentStepIndex: Number,
    language: String
  };

  connect() {
    if (this.hasSavedStepIndexValue) {
      this.initializeTour();
    }
  }

  initializeTour() {
    const tour = this.initializeShepherdTour();
    this.addStepsToTour(tour);
    const startIndex = this.determineStartIndex();
    tour.start();
    tour.show(startIndex);
  }
  
  initializeShepherdTour() {
    // Initialize and return a new Shepherd tour with specific options
    return new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        cancelIcon: { enabled: true },
        classes: 'shepherd-theme-arrows',
        scrollTo: true
      },
      onComplete: () => this.finishWizard()
    });
  }
  
  addStepsToTour(tour) {
    // Add each step to the tour
    this.steps = this.configValue.steps; // Extract steps from the config
    this.steps.forEach((step, index) => {
      tour.addStep({
        title: step.title[this.languageValue],
        text: step.description[this.languageValue],
        attachTo: { 
          element: `#${step.id}`, 
          on: step.position || 'bottom' 
        },
        when: { show: () => this.handleStepShow(index) },
        buttons: this.getStepButtons(tour)
      });
    });
  }
  
  handleStepShow(index) {
    // Handle the 'show' event for each step
    this.currentStepIndexValue = index;
    this.updateProgressOnServer(index);
  }

  getStepButtons(tour) {
    const buttonConfig = this.configValue.buttons; // Extract button config
    const buttons = [];
  
    Object.keys(buttonConfig).forEach(key => {
      const button = buttonConfig[key];
      if (button[this.languageValue]) {
        let action;
        if (key === 'done') { // Assuming 'done' is the key for the "Terminer" button
          action = () => {
            this.finishWizard();
            tour.complete(); // Close the tour
          };
        } else {
          action = tour[key]; // Default action for other buttons
        }
  
        buttons.push({
          text: button[this.languageValue],
          action: action,
          classes: button.classes || '',
          // Add other options like 'secondary' or 'disabled' as needed
        });
      }
    });
  
    return buttons;
  }
  
  determineStartIndex() {
    // Determine and return the starting index for the tour
    return this.savedStepIndexValue >= 0 && this.savedStepIndexValue < this.steps.length 
      ? this.savedStepIndexValue 
      : 0;
  }
  

  updateProgressOnServer(stepIndex) {
    fetch('/tour/update_step', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ step: stepIndex })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  finishWizard() {
    fetch('/tour/complete', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(data => {
      console.log('Tutorial completed');
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}
