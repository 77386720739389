import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // Define the targets used in the controller
  static targets = ["companyInfo", "individualInfo", "billableKindIndividualCheckbox", "billableKindCompanyCheckbox"];


  // Connect method called when the controller is instantiated
  connect() {
    this.initializeBillingInformations();
  }

  toggle(event) {
    // Get the value of the selected radio button
    let selectedValue;
    if (event) {
      selectedValue = event.target.value;
    } else {
      selectedValue = this.billableKindIndividualCheckboxTarget.checked ? "individual" : "company";
    }
    // Show or hide the sections based on the selected value
    if (selectedValue === "individual") {
      this.individualInfoTarget.classList.remove("hidden");
      this.companyInfoTarget.classList.add("hidden");
    } else if (selectedValue === "company") {
      this.individualInfoTarget.classList.add("hidden");
      this.companyInfoTarget.classList.remove("hidden");
    }
  }

  initializeBillingInformations(){
    // Get the value of the currently checked radio button
    const selectedValue = this.billableKindIndividualCheckboxTarget.checked ? "individual" : "company";

    this.toggle({ target: { value: selectedValue } });
  }
}
